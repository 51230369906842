import { Track } from "@prisma/client";
import TrackItem from "./TrackItem";

export default function TrackList(props: {
  editTrack: (track: Track) => void;
  items: Track[];
  deleteTrack: (track: Track) => void;
}) {
  function renderItem(item) {
    return (
      <TrackItem
        key={item.id}
        track={item}
        deleteTrack={props.deleteTrack}
        editTrack={props.editTrack}
      />
    );
  }

  return (
    <div className="flex-1 flex flex-col overflow-auto bg-gray-800 transition pb-28">
      <div className="flex-1">
        {props.items.length === 0 && (
          <div className="text-white py-4 px-4 border-t border-dashed border-gray-800 flex max-w-3xl mx-auto text-xs">
            <p>No items found.</p>
          </div>
        )}

        {props.items.map((i) => renderItem(i))}
      </div>
    </div>
  );
}
