import { User } from "@prisma/client";
import Icon from "./Icon";

export default function AddSlide(props: {
  user: User;
  addItem: () => void;
  register: () => void;
  logout: () => void;
}) {
  const today = new Date();

  return (
    <>
      <div className="h-full w-screen flex flex-col items-center justify-center bg-gray-600 text-white text-sm">
        <div className="flex-1 flex items-end">
          <button
            className="flex items-center leading-none mb-12 h-10 px-10"
            onClick={props.addItem}
          >
            <Icon icon="add" className="mr-1 w-5 h-5" /> New Category
          </button>
        </div>
        <div className="text-2xs text-gray-700 uppercase tracking-widest flex-1 flex flex-col items-center">
          <a href="https://strazi.org" target="_blank">
            ©{today.getFullYear()} strazi.org
          </a>

          <div className="flex items-center gap-2">
            {props.user?.email && (
              <div>
                Logged in as {props.user.email}
                <div className="text-center">
                  <button
                    className="uppercase"
                    type="button"
                    onClick={props.logout}
                  >
                    Logout
                  </button>
                </div>
              </div>
            )}

            {!props.user?.email && (
              <>
                <button
                  className="uppercase"
                  type="button"
                  onClick={props.register}
                >
                  Register
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
