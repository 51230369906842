import { Category, Track } from "@prisma/client";
import { isSameDay } from "date-fns";
import { useState } from "react";
import CategoryTitle from "./CategoryTitle";
import Chart from "./Chart";
import TrackList from "./TrackList";

interface Props {
  category: Category & { tracks: Track[] };
  deleteCategory: (category: Category) => void;
  deleteTrack: (track: Track) => void;
  editTrack: (track: Track) => void;
  editCategory: (category: Category) => void;
}

export default function CategorySlide(props: Props) {
  const [selectedDate, setSelectedDate] = useState<Date>(null);

  const items = props.category.tracks.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  function selectDate(date) {
    console.log("date", date);

    if (!date || (selectedDate && isSameDay(date, selectedDate))) {
      setSelectedDate(null);
    } else {
      setSelectedDate(new Date(date));
    }
  }

  let filteredItems = items;

  if (selectedDate) {
    filteredItems = items.filter((item) => isSameDay(item.date, selectedDate));
  }

  // console.log("filteredItems", filteredItems);
  // console.log("selectedDate", selectedDate);
  // console.log("items", props.items);

  return (
    <div
      className={`h-full w-screen flex flex-col bg-gray-200 landscape:flex-row`}
    >
      <div className="flex-1 flex flex-col overflow-auto">
        <div>
          <div className="mx-auto px-2 max-w-3xl border-dashed border-gray-500">
            <CategoryTitle
              category={props.category}
              deleteCategory={props.deleteCategory}
              editCategory={props.editCategory}
              count={items.length}
            />
          </div>
        </div>
        <div className="flex-1 pt-2 pb-2">
          <Chart
            key={filteredItems.length}
            items={items}
            selectDate={selectDate}
            selectedDate={selectedDate}
            category={props.category}
          />
        </div>
      </div>
      <TrackList
        items={filteredItems}
        editTrack={props.editTrack}
        deleteTrack={props.deleteTrack}
      />
    </div>
  );
}
