import Icon from "./Icon";

function AddButton(props) {
  return (
    <button
      className="shadow-xl flex w-16 h-16 rounded-full items-center justify-center fixed bottom-6 left-0 right-0 mx-auto z-10 focus:outline-none border-white border-2 bg-teal-500 active:bg-teal-600 transition-all duration-150"
      onClick={props.onClick}
    >
      <Icon icon="add" fill="#fff" className="w-10 h-10 text-white" />
    </button>
  );
}

export default AddButton;
