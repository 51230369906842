import { Track } from "@prisma/client";
import { format, isSameDay } from "date-fns";
import { useEffect, useState } from "react";
import tw from "../utils/tw";
import Icon from "./Icon";

interface Props {
  track: Track;
  deleteTrack: (track: Track) => void;
  editTrack: (track: Track) => void;
}

export default function TrackItem(props: Props) {
  const today = new Date();
  const trackDate = new Date(props.track.date);
  const isToday = isSameDay(today, trackDate);
  const [value, setValue] = useState(trackDate);
  const isEdited = trackDate.getTime() !== value.getTime();

  useEffect(() => {
    if (isEdited) {
      console.log("setting");
      setValue(trackDate);
    }
  }, [props.track]);

  function confirmDelete() {
    if (
      window.confirm(
        `Are you sure this item on ${format(
          trackDate,
          "MMM d, yyyy 'at' h:mm a"
        )}?`
      )
    ) {
      props.deleteTrack(props.track);
    }
  }

  function onChange(e) {
    const date = new Date(e.target.value);

    // console.log("date", date);

    setValue(date);
  }

  function editDate() {
    console.log("value", value);

    props.editTrack({
      ...props.track,
      date: new Date(value),
    });
  }

  // console.log("value", value);

  return (
    <div
      className={tw(
        isToday ? "text-white" : "text-gray-300",
        "px-2",
        "border-b",
        "border-dashed",
        "border-gray-900",
        "items-center",
        "flex",
        "max-w-3xl",
        "mx-auto"
      )}
    >
      <div
        className={tw(
          "flex-1",
          "relative",
          "py-2",
          "text-xs",
          `${isToday ? "font-bold" : ""}`
        )}
      >
        <form onSubmit={editDate}>
          <input
            type="datetime-local"
            value={format(value, "yyyy-MM-dd'T'HH:mm")}
            className="text-black absolute left-0 top-0 w-full h-full focus:outline-none rounded-none opacity-0"
            onChange={onChange}
            // onBlur={editDate}
            // onSubmit={editDate}
          />
        </form>
        {format(value, "EEE, MMM d, yyyy")}{" "}
      </div>

      <div
        className={tw(isToday ? "text-gray-400" : "text-gray-500", "text-xs")}
      >
        {format(value, "h:mm a")}
      </div>

      {!isEdited && (
        <div>
          <button
            className="ml-1 py-2 block focus:outline-none w-10"
            onClick={confirmDelete}
          >
            <Icon icon={"clear"} className={`text-gray-900 w-5 h-5 ml-auto`} />
          </button>
        </div>
      )}

      {isEdited && (
        <div>
          <button
            className="ml-1 py-2 block focus:outline-none w-10"
            onClick={editDate}
          >
            <Icon icon={"check"} className={`text-green-700 w-5 h-5 ml-auto`} />
          </button>
        </div>
      )}
    </div>
  );
}
