import { Category, Track } from "@prisma/client";
import { format } from "date-fns";
import { max } from "lodash";
import { useEffect } from "react";
import tw from "../utils/tw";

interface Props {
  selectDate: (date) => void;
  selectedDate: Date;
  items: Track[];
  category: Category;
}

function isSameDay(a: Date, b: Date) {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
}

export default function Chart(props: Props) {
  // const node = useRef(null);
  const today = new Date();

  function handleClickOutside(e) {
    // if (node.current?.contains(e.target)) {
    //   console.log("true");
    //   return;
    // }
    // props.selectDate(null);
  }

  useEffect(() => {
    if (props.selectedDate) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.selectedDate]);

  const dates = props.items.reduce((acc, i) => {
    const date = new Date(i.date);
    const key = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

    // console.log("day", day);

    if (!acc[key]) {
      const count = props.items.filter((item) =>
        isSameDay(new Date(item.date), date)
      ).length;
      const isToday = isSameDay(today, date);

      acc[key] = {
        isToday,
        count,
        date,
        height: 0,
      };
    }
    return acc;
  }, {});

  const counts = Object.keys(dates).map((k) => dates[k].count || 0);
  const maxCount = max(counts);

  Object.keys(dates).forEach((k) => {
    const height = (dates[k].count / maxCount) * 100;
    const roundedHeight = Math.round(height * 10) / 10 + 0.2;

    dates[k].height = roundedHeight;
  });

  const ticks = [];
  let i = 0;

  while (i < maxCount && i < 40) {
    ticks.push(
      <div
        key={i}
        className="flex-1 pt-1 text-gray-500 text-xs border-t border-dashed border-gray-300"
      >
        {/* {i} */}
      </div>
    );
    i++;
  }

  // console.log("data", data);
  // console.log("maxCount", maxCount);

  function renderColumn(key) {
    const d = dates[key];

    // console.log("d", d);

    return (
      <div key={key}>
        <div className="h-full flex flex-col mr-2 ml-2">
          <div className="flex flex-1">
            <div className={`w-full`}>
              <div
                onClick={() => {
                  props.selectDate(d.date);
                }}
                className={`flex flex-col justify-end h-full`}
              >
                <div
                  className={tw(
                    "transition-all",
                    `${
                      d.isToday
                        ? "font-bold text-opacity-100"
                        : "text-opacity-50"
                    }`,
                    "text-white",
                    "duration-150",
                    "ease-linear",
                    "text-center",
                    "text-2xs",
                    "pt-1",
                    "cursor-pointer",
                    `bg-${props.category.color}-600`
                    // `${isSameDay(props.selectedDate, d) ? "opacity-60" : ""}`
                  )}
                  style={{
                    height: `${d.height}%`,
                  }}
                >
                  {d.count}
                </div>
              </div>
            </div>
          </div>
          <div
            className={tw(
              "pt-1",
              "text-2xs",
              "text-center",
              "leading-tight",
              "text-gray-700",
              "h-7",
              `${d.isToday ? "font-bold" : ""}`
            )}
            key={i}
            onClick={() => {
              props.selectDate(d);
            }}
          >
            <div>{format(d.date, "M/dd")}</div>
            <div className="text-gray-400">{format(d.date, "yyyy")}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="chart flex flex-col mx-auto max-w-3xl h-full">
      <div className="relative h-full">
        <div className="relative z-10 h-full overflow-x-auto flex">
          {Object.keys(dates).map((k) => renderColumn(k))}
        </div>
        <div className="absolute z-0 bottom-7 left-0 top-0 flex flex-col-reverse w-full border-b border-dashed border-gray-300">
          {ticks}
        </div>
      </div>
    </div>
  );
}
