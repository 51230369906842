"use client";

import { Category, Track, User } from "@prisma/client";
import { deleteCookie, setCookie } from "cookies-next";
import cuid from "cuid";
import { addDays } from "date-fns";
import { useRouter } from "next/navigation";
import { Suspense, useEffect, useRef, useState } from "react";
import SuperJSON from "superjson";
import { HashNavigation } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import colors from "../utils/colors";
import getRandomInt from "../utils/getRandomInt";
import AddButton from "./AddButton";
import AddSlide from "./AddSlide";
import CategorySlide from "./CategorySlide";

interface Props {
  categories: string[];
  user: User;
}

export function Slider(props: Props) {
  const swiperRef = useRef<SwiperClass>(null);
  const router = useRouter();

  const [localCategories, setLocalCategories] = useState<
    Array<Category & { tracks: Track[] }>
  >([]);

  let categories: Array<Category & { tracks: Track[] }> = props.categories.map(
    (c) => SuperJSON.parse(c)
  );

  if (!props.user) {
    categories = localCategories;
  }

  useEffect(() => {
    const existing = localStorage.getItem("categories");

    if (existing) {
      setLocalCategories(JSON.parse(existing));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(localCategories));
  }, [localCategories]);

  // console.log("props.user", props.user);
  // console.log("props", props.categories);

  useEffect(() => {
    if (props.user?.id) {
      setCookie("trackzi", props.user.id, {
        expires: new Date(
          new Date().getFullYear() + 1,
          new Date().getMonth(),
          new Date().getDate()
        ),
      });
    }
  }, []);

  function onSwiper(ref: SwiperClass) {
    console.log("swiperRef", swiperRef);
    swiperRef.current = ref;
  }

  async function deleteCategory(category: Category) {
    if (window.confirm(`Are you sure you want to delete ${category.name}?`)) {
      if (props.user) {
        await fetch(`/api/category/${category.id}`, {
          method: "DELETE",
        });

        swiperRef.current.slideTo(props.categories.length + 1);

        router.refresh();
      } else {
        setLocalCategories((current) => {
          const newCategories = [...current].filter(
            (c) => c.id !== category.id
          );
          return newCategories;
        });
      }
    }
  }

  async function deleteTrack(track: Track) {
    console.log("track", track);

    if (props.user) {
      await fetch(`/api/track/${track.id}`, {
        method: "DELETE",
      });

      router.refresh();
    } else {
      setLocalCategories((current) => {
        const newCategories = [...current];
        const currentCategory = newCategories.findIndex(
          (c) => c.id === track.categoryId
        );

        newCategories[currentCategory].tracks = newCategories[
          currentCategory
        ].tracks.filter((t) => t.id !== track.id);

        return newCategories;
      });
    }
  }

  async function editTrack(newTrack: Track) {
    const track: Track = {
      createdAt: null,
      updatedAt: null,
      date: newTrack.date,
      id: newTrack.id,
      categoryId: newTrack.categoryId,
    };

    if (props.user) {
      await fetch(`/api/track/${newTrack.id}`, {
        method: "PUT",
        body: JSON.stringify(track),
      });

      router.refresh();
    } else {
      setLocalCategories((current) => {
        const newCategories = [...current];
        const currentCategory = newCategories.findIndex(
          (c) => c.id === track.categoryId
        );
        const currentTrack = newCategories[currentCategory].tracks.findIndex(
          (t) => t.id === track.id
        );

        newCategories[currentCategory].tracks[currentTrack] = track;

        return newCategories;
      });
    }
  }

  async function addTrack({ newTrack, isNewCategory = false }) {
    console.log("newTrack", newTrack);
    const track: Track = {
      id: null,
      createdAt: null,
      updatedAt: null,
      date: newTrack.date,
      categoryId: newTrack.category,
    };

    console.log("track", track);

    if (props.user) {
      await fetch("/api/track", {
        method: "POST",
        body: JSON.stringify(track),
      });

      router.refresh();
    } else {
      setLocalCategories((current) => {
        console.log("current", current);
        const newCategories = [...current];
        const index = newCategories.findIndex((c) => c.id === track.categoryId);

        newCategories[index].tracks.push({
          ...track,
          id: `local-${cuid()}`,
        });

        console.log("newCategories", newCategories);

        return newCategories;
      });
    }
  }

  async function addItem() {
    const currentCategory = categories[swiperRef.current.activeIndex];

    // console.log("currentCategory", swiper.current.activeIndex, currentCategory);

    const date = new Date();

    const newTrack = {
      date,
      category: currentCategory?.id,
    };

    if (process.env.NODE_ENV === "development") {
      newTrack.date = addDays(newTrack.date, getRandomInt(7));
    }

    if (newTrack.category) {
      addTrack({ newTrack });
    } else {
      const newCategory: Category & { tracks: Track[] } = {
        id: null,
        createdAt: null,
        updatedAt: null,
        name: `Category ${categories.length + 1}`,
        date,
        color: colors[getRandomInt(colors.length - 1)],
        userId: null,
        tracks: [],
      };

      if (props.user) {
        await fetch("/api/category", {
          method: "POST",
          body: JSON.stringify({
            ...newCategory,
            tracks: undefined,
          }),
        });

        router.refresh();
      } else {
        setLocalCategories((current) => {
          const newCategories = [...current];

          newCategories.push({
            ...newCategory,
            id: `local-${cuid()}`,
          });

          return newCategories;
        });
      }
    }
  }

  function updateLocalCategory(category) {
    setLocalCategories((current) => {
      const newCategories = [...current].filter((c) => c.id !== category.id);
      newCategories.push(category);

      return newCategories;
    });
  }

  async function editCategory(category: Category) {
    if (props.user) {
      await fetch(`/api/category/${category.id}`, {
        method: "PUT",
        body: JSON.stringify({
          name: category.name,
          color: category.color,
        }),
      });

      router.refresh();
    } else {
      updateLocalCategory(category);
    }
  }

  async function register() {
    const email = window.prompt("Enter an email (or similar)");

    console.log("email", email);

    const result = await fetch("/api/register", {
      method: "POST",
      body: JSON.stringify({
        email,
        categories,
      }),
    });

    const json: { id: string } = await result.json();
    setCookie("trackzi", json.id, {
      expires: new Date(
        new Date().getFullYear() + 1,
        new Date().getMonth(),
        new Date().getDate()
      ),
    });
    localStorage.clear();
    router.refresh();
  }

  async function logout() {
    deleteCookie("trackzi");
    localStorage.clear();
    router.refresh();
  }

  console.log("categories", categories);
  console.log("swiperRef", swiperRef);

  return (
    <div className="absolute left-0 top-0 w-full h-full bg-rose-200">
      <Swiper
        // key={`swiper-${categories.length}`}
        noSwipingClass="chart"
        className="h-full"
        modules={[HashNavigation]}
        onSwiper={onSwiper}
      >
        <Suspense fallback={<p>Loading feed...</p>}>
          {categories?.map((c, i) => {
            return (
              <SwiperSlide key={c.id} data-hash={c.id}>
                <CategorySlide
                  category={c}
                  deleteCategory={deleteCategory}
                  deleteTrack={deleteTrack}
                  editTrack={editTrack}
                  editCategory={editCategory}
                />
              </SwiperSlide>
            );
          })}
        </Suspense>
        <SwiperSlide key="new" data-hash="new">
          <AddSlide
            addItem={addItem}
            user={props.user}
            register={register}
            logout={logout}
          />
        </SwiperSlide>
      </Swiper>

      <AddButton onClick={addItem} />
    </div>
  );
}
