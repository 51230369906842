import { Category } from "@prisma/client";
import React, { useState } from "react";
import colors from "../utils/colors";
import tw from "../utils/tw";
import Icon from "./Icon";

export default function CategoryTitle(props: {
  editCategory: (category: Category) => void;
  category: Category;
  deleteCategory: (category: Category) => void;
  count: number;
}) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(props.category.name);

  function onChange(e) {
    const name = e.target.value;
    setValue(name);
  }

  function updateTitle() {
    props.editCategory({
      ...props.category,
      name: value,
    });
  }

  function setColor(color) {
    props.editCategory({
      ...props.category,
      color,
    });
  }

  function deleteCategory() {
    props.deleteCategory(props.category);
  }

  // console.log("props.category.name", props.category.name);

  return (
    <React.Fragment>
      <div className="flex items-stretch mt-2 mb-2">
        {!editing && (
          <h1 className="text-gray-900 flex-1 flex items-center">
            {props.category.name}{" "}
            <span className="text-gray-400 text-sm ml-1">
              ({props.count || 0})
            </span>
          </h1>
        )}
        {editing && (
          <div className="flex flex-1">
            <div className="flex-1">
              <form onSubmit={updateTitle}>
                <input
                  type="text"
                  className="bg-gray-100 px-3 py-2 w-full h-9 focus:outline-none focus:bg-white rounded-none"
                  value={value}
                  onChange={onChange}
                  autoFocus
                />
              </form>
            </div>
          </div>
        )}

        <div>
          <button
            className="text-xs text-gray-400 uppercase block pl-4 h-9 focus:outline-none"
            onClick={() => {
              if (!editing) {
                setEditing(true);
              } else {
                updateTitle();
                setEditing(false);
              }
            }}
          >
            <Icon
              icon={editing ? "check" : "edit"}
              className={tw("w-5 h-5", editing && "text-green-700")}
            />
          </button>
        </div>

        {editing && (
          <button
            className="text-xs text-rose-700 uppercase block ml-9 h-9 focus:outline-none"
            onClick={deleteCategory}
          >
            <Icon icon={"clear"} className="w-5 h-5" />
          </button>
        )}
      </div>
      {editing && (
        <div className="flex h-6 -mr-1">
          {colors.map((c) => {
            return (
              <div
                key={c}
                onClick={() => setColor(c)}
                className={`cursor-pointer bg-${c}-600 h-full flex-1 mr-1`}
              />
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
}
